import Component from '../lib/Component'
import Swiper from 'swiper';

export default class GallerySlider2 extends Component {
    mount() {
        new Swiper(this.element, {
            spaceBetween: 0,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
        });
    }
}

