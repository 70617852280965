require('intersection-observer');

import Component from '../lib/Component'
import NijushiSekki from "../services/24"
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
import PerfectScrollbar from 'perfect-scrollbar';
gsap.registerPlugin(ScrollToPlugin)

export default class Body extends Component {
    NijushiSekki:NijushiSekki
    mount() {
        const browser:string = this.parser.getBrowser().name;
        let target:HTMLElement | string
        document.documentElement.dataset.browser = browser;
        this.scroll()
        this.addstylesheet();
    }

    scroll(){
          const Hero = document.querySelector(".js-hero");

        if(Hero){
            (new IntersectionObserver(function (entries) {
                if (entries[0].intersectionRatio == 0) {
                    document.body.classList.add("is-scroll")
                }else{
                    document.body.classList.remove("is-scroll")
                }
            })).observe(Hero);
        }
    }

    addstylesheet(){
        this.NijushiSekki = new NijushiSekki();
        document.write('<link rel="stylesheet" id="nijushisekki" href="/wp/wp-content/themes/eirakuya/css/color'+this.NijushiSekki.currentKoyomi+'.css" type="text/css" media="screen" />');
    }
}

