import Component from '../lib/Component'
import Swiper from 'swiper'

export default class HeroSlider extends Component {
    mount() {
        new Swiper(this.element, {
            slidesPerView: 1,
            effect: 'fade',
            loop: true,
            autoplay: {
                delay: 7000,
            },
            pagination: {
                el: '#js-hero-slider-pagination',
                clickable: true
                /*type:'custom',
                renderCustom: function(swiper, current, total) {
                    var names = [];

                    var text = "<ul>";
                    for (let i = 1; i <= total; i++) {
                        if (current == i) {
                            text += "<li><a class='active'><span class='cd-dot'></span><span class='cd-label'>Item 1</span></a></li>";
                        } else {
                            text += "<li><a><span class='cd-dot'></span><span class='cd-label'></span></a></li>";
                        }

                    }
                    text += "</ul>";
                    return text;
                }*/
            },
            on: {
                init: function (el) {
                    let sliderMaxNum;
                    if (this.loopedSlides) {
                        sliderMaxNum = this.slides.length - 2;
                    } else {
                        sliderMaxNum = this.slides.length;
                    }
                    let cc = ( '00' + (this.realIndex +1) ).slice( -2 );
                    let ct = ( '00' + sliderMaxNum ).slice( -2 );

                    let newElement = document.createElement("div");
                    var newContent = document.createTextNode(cc+'_'+ct);
                    newElement.appendChild(newContent);
                    newElement.setAttribute("id","js-hero-slider-count-num");

                    let parentDiv = document.getElementById("js-hero-slider-count");
                    if(parentDiv){
                        parentDiv.insertBefore(newElement, parentDiv.firstChild);
                    }
                },
                slideChange: function () {
                    let sliderMaxNum;
                    if (this.loopedSlides) {
                        sliderMaxNum = this.slides.length - 2;
                    } else {
                        sliderMaxNum = this.slides.length;
                    }
                    let cc = ( '00' + (this.realIndex +1) ).slice( -2 );
                    let ct = ( '00' + sliderMaxNum ).slice( -2 );
                    let parentDiv = document.getElementById("js-hero-slider-count-num");
                    if(parentDiv){
                        parentDiv.textContent= cc+'_'+ct;
                    }
                },
            }
        });
    }
}

