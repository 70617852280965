import './polyfill'
import SplashScreen from "./components/SplashScreen"
import Body from './components/Body'
import PageScroll from "./components/PageScroll"
import MenuButton from "./components/MenuButton"
import HeroSlider from "./components/HeroSlider"
import HeroSubSlider from "./components/HeroSubSlider"
import TopNewsSlider from "./components/TopNewsSlider"
import GallerySlider from "./components/GallerySlider"
import GallerySlider2 from "./components/GallerySlider2"
import NSSlider from "./components/24Slider"
import NSSliderGallery from "./components/24SliderGallery"
import DropDown from "./components/DropDown"
import Tab from "./components/Tab"

import {configure} from './config'
import Time from './lib/Time'
import ComponentManager from './lib/ComponentManager'
import {domLoaded} from './lib/promisfy'





const applicationSequence = async () => {
    configure()
    Time.create().start('init')
    await domLoaded()

    const _ComponentManager = new ComponentManager({
        [window.config.SPLASH_SCREEN_SELECTOR]: SplashScreen,
        'body': Body,
        "[href^='#']": PageScroll,
        '.js-menu-button': MenuButton,
        '#js-hero-slider': HeroSlider,
        '.js-hero-sub-slider': HeroSubSlider,
        '#js-top-news-slider': TopNewsSlider,
        /*'.js-gallery-slider': GallerySlider,*/
        '.js-gallery-slider2': GallerySlider2,
        '.js-24-slider': NSSlider,
        '.js-24-slider-gallery': NSSliderGallery,
        '.js-dropdown': DropDown,
        '.js-tab': Tab,
    })
    _ComponentManager.start()

   console.log('ddd');
}


applicationSequence()