/* @flow */
import EventEmitter from 'events'

export default class NijushiSekki extends EventEmitter {
    KoyomiTotal:number = 24
    currentKoyomi:number
    koyomiCalender:{[key: string]: string} = {}

    constructor() {
        super()
        this.get24sekki()
    }

    get24sekki(){
        const Now = new Date()
        //const Now = new Date(2020, 5, 1);
        const param:{[key: string]: number}[] = [ { d:6.3811, a:0.242778 }, { d:21.1046, a:0.242765 }, { d:4.8693, a:0.242713 }, { d:19.7062, a:0.242627 }, { d:6.3968, a:0.242512 }, { d:21.4471, a:0.242377 }, { d:5.6280, a:0.242231 }, { d:20.9375, a:0.242083 }, { d:6.3771, a:0.241945 }, { d:21.9300, a:0.241825 }, { d:6.5733, a:0.241731 }, { d:22.2747, a:0.241669 }, { d:8.0091, a:0.241642 }, { d:23.7317, a:0.241654 }, { d:8.4102, a:0.241703 }, { d:24.0125, a:0.241786 }, { d:8.5186, a:0.241898 }, { d:23.8896, a:0.242032 }, { d:9.1414, a:0.242179 }, { d:24.2487, a:0.242328 }, { d:8.2396, a:0.242469 }, { d:23.1189, a:0.242592 }, { d:7.9152, a:0.242689 }, { d:22.6587, a:0.242752 } ]
        var day:number[] = [];
        var year:number = Now.getFullYear()

        for (var i:number = 0; i < param.length; i++) {
            if (i < 4) day.push(this.calculateDate(year-1, param[i].d, param[i].a))
            else day.push(this.calculateDate(year, param[i].d, param[i].a))
        }


        var koyomiFlag = false
        day.forEach((elem, index) =>{
            var koyomiMonth = Math.ceil((index+1) / 2)
            var koyomiDay = Math.floor(elem)
            var koyomiNumber = index + 1;
            this.koyomiCalender[koyomiNumber] = this.toKanziNum(koyomiMonth)+'月'+this.toKanziNum(koyomiDay)+'日'
            var koyomiDate = new Date(Now.getFullYear(), (koyomiMonth-1), koyomiDay);

            if(!koyomiFlag && Now < koyomiDate){
                koyomiFlag = true
                if(koyomiNumber == 1){
                    this.currentKoyomi = this.KoyomiTotal;
                }else{
                    this.currentKoyomi = koyomiNumber-1;
                }
            }
            if (!koyomiFlag && Object.is(day.length - 1, index)) {
                this.currentKoyomi = koyomiNumber;
            }

        });

    }

    calculateDate(y:number, d:number, a:number){
        return d + a * (y - 1900) - Math.floor((y - 1900) / 4);
    }

    toKanziNum(_num){
        var num = String(_num);
        var ten = "十";
        var kannum = new Array("〇","一","二","三","四","五","六","七","八","九");
        var strA="";
        var strB="";
        var length = num.length;

        if(length==1){
            return kannum[eval(num)];
        }else if(length==2){
            if(num.charAt(0) == true){
                if(num.charAt(1)==0){
                    strA=ten;
                }else{
                    strA=ten;
                    strB=kannum[eval(num.charAt(1))];
                }
            }else{
                if(num.charAt(1)==0){
                    strA=kannum[eval(num.charAt(0))];
                    strB=ten;
                }else{
                    strA=kannum[eval(num.charAt(0))]+ten;
                    strB=kannum[eval(num.charAt(1))];
                }
            }
        }
        return strA+strB;
    }

}
