import Component from '../lib/Component'
import ComponentArray from '../lib/ComponentArray'
import TabStore from '../store/Tab'
import TabButton from './TabButton'
import {autorun} from 'mobx'

export type ComponentList = () => Component[]

export default class Tab extends Component {
    store = new TabStore()
    buttons:ComponentArray
    mount() {
        this.buttons = new ComponentArray(TabButton, () => this.element.querySelectorAll('.js-tab-button'))
        autorun(() => {
            this.buttons.map((_c) => {
                const c = <TabButton>_c
                if(!c.parentComponent){
                    c.setParent(this);
                }
                if (this.store.controlName != c.controlElementID) {
                    c.hide()
                }
            })
        })

        const hash:String = window.location.hash;
        if(hash){
            this.buttons.filter((button:TabButton,index) => '#'+button.controlElementID == hash).map(c => {
                c.emit('click');
            });
        }else{
            this.buttons.filter((button,index) => index == 0).map(c => {
                c.emit('click');
            });
        }


    }
    view() {

    }
}

