import Component from '../lib/Component'
import Swiper from 'swiper'

export default class HeroSubSlider extends Component {
    mount() {
        new Swiper(this.element, {
            slidesPerView: 1,
            effect: 'fade',
            loop: true,
            autoplay: {
                delay: 7000,
            }
        });
    }
}

