import Component from '../lib/Component'
import Swiper from 'swiper'

export default class TopNewsSlider extends Component {
    mount() {
        new Swiper(this.element, {
            pagination: {
                el: '#js-top-news-slider-pagination',
                clickable: true
            }
        });
    }

}

