import Component from '../lib/Component'
import Tab from './Tab'
import gsap from 'gsap'

export default class TabButton extends Component {
    controlElement: HTMLElement
    controlElementID:string | null
    direction: string | undefined
    elements: NodeListOf<HTMLElement>
    gsap:GSAPTween
    parentComponent: Tab

    mount() {
        const _controlElement = this.getControl()
        if(_controlElement){
            this.controlElement = _controlElement;
            this.element.setAttribute('aria-selected','false');
            this.elements = document.querySelectorAll('[aria-controls='+this.controlElementID+']')
            this.controlElement.setAttribute('aria-hidden','true');
            this.on('click', () => {
                this.show();
            })
        }
    }

    show(){
        this.changeStatus(true)
        this.parentComponent.store.controlName = this.controlElementID;
        gsap.to(this.controlElement,{opacity:1, display:"block",duration:.2});
    }

    hide(){
        this.changeStatus(false)
        gsap.to(this.controlElement,{opacity:0, display:"none",duration:0});
    }

    getControl(){
        this.controlElementID = this.element.getAttribute('aria-controls')
        return this.controlElementID? document.getElementById(this.controlElementID) : null
    }

    setParent(component:Tab){
        this.parentComponent = component;
    }

    changeStatus(status:boolean){
        Array.from(this.elements).forEach((el) => {
            el.setAttribute('aria-selected',String(status))
        });
        this.controlElement.setAttribute('aria-hidden',String(!status))
    }


}

