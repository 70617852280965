/* @flow */
import EventEmitter from 'events'
let singleton: WindowElement | null = null

export default class WindowElement extends EventEmitter {

    static CHANGE_ORIENTATION = 'change_orientation'
    static RESIZE = 'resize'

    initialWidth: number
    initialHeight: number
    orientationChangeWidth: number
    orientationChangeHeight: number
    container: HTMLElement
    windowScrollY:number

    static create() {
        if (singleton) {
            if (process.env.NODE_ENV !== 'production') {
                // console.log(singleton.constructor.name, singleton.listenerCount('change_orientation'))
            }
            return singleton
        }
        singleton = new WindowElement()
        singleton.setMaxListeners(0)
        return singleton
    }

    constructor() {
        super()

        this.initialWidth = window.innerWidth
        this.initialHeight = window.innerHeight
        window.addEventListener('resize', () => {
            this.emit(WindowElement.RESIZE)
        })

        this.preventDefaultHandler = this.preventDefaultHandler.bind(this)
    }

    preventDefaultHandler(e: Event) {
        e.preventDefault()
    }

}
