import Component from '../lib/Component'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

export default class NSSliderButton extends Component {
    dateElement:HTMLElement|null
    slide:any
    date:string
    koyomi:number
    mount() {
        this.koyomi = Number(this.element.dataset.koyomi);
        this.dateElement = this.element.querySelector('.js-koyomi-date');

    }

    setDate(calender:{[key: string]: string},currentKoyomi:number){
        this.date = calender[this.koyomi]
        if(this.dateElement){
            this.dateElement.innerHTML = this.date
        }
        let slideDate = document.querySelectorAll('[data-koyomi="'+this.koyomi+'"] .nis-Gallery_Date');
        slideDate.forEach((element, i) =>{
            element.innerHTML=this.date
        });
        let order,diff = 0
        diff = this.koyomi - currentKoyomi;
        if(diff < 0){
            order = 24 + diff + 1
        }else{
            order = diff + 1
        }
        this.element.classList.add("order"+order)
        this.element.setAttribute('style',"order:"+order)
    }

    setClick(SliderMain:any){
        this.slide = SliderMain
        this.on('click',(event)=>{
            /*let Date = this.slide.$el[0].querySelectorAll('[data-koyomi="'+this.koyomi+'"] .nis-Gallery_Date')

            Date.forEach((element, i) =>{
                element.innerHTML=this.date
            });*/

            /*let Slide = this.slide.$el[0].querySelectorAll('.swiper-slide[data-koyomi="'+this.koyomi+'"]')
            if(Slide[0]){
                console.log(Slide[0]);
                Slide[0].dispatchEvent(new Event('click'));
            }*/
            this.slide.slideTo(this.koyomi - 1)
            let scrollOffset = 0;
            let header =  document.getElementById('js-fix-header');
            if(this.mediaQuery.isDesktop() && header){
                scrollOffset = header.offsetHeight;
            }
            gsap.to(window,{
                duration: 0.8,
                scrollTo:{y:'#main', offsetY:scrollOffset,autoKill:false}
            })
        })
    }

}

