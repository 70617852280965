import Component from '../lib/Component'
import Swiper from 'swiper'
import ComponentArray from "../lib/ComponentArray"
import NSSliderGalleryButton from "./24SliderGalleryButton"

export default class NSSliderGallery extends Component {
    buttons:ComponentArray
    mount() {

        let sliderMain = this.element.querySelectorAll('.js-24-slider-gallery-main')
        let sliderNav = this.element.querySelectorAll('.js-24-slider-gallery-thumb')

        let mainArray:any = [];

        sliderMain.forEach(function(element, i) {
            let galleryTop = new Swiper(element, {
                effect: 'fade',
                spaceBetween: 0,
                slideToClickedSlide:true,
                allowTouchMove:false,
                preventClicks: false,
            });

            mainArray.push(galleryTop);
        });

        sliderNav.forEach(function(element, i) {
                 /*let galleryThumbs = new Swiper(element, {
                spaceBetween: 13,
                slidesPerView: 5,
                loop: false,
                allowTouchMove:false
             });
            navArray.push(galleryThumbs);*/
        });


        if (sliderMain.length > 0 && sliderNav.length > 0) {
            let numberOfSlides = mainArray.length || 0;

            if (mainArray.length !== sliderNav.length) {
                console.warn('multipleSwiperSlides: Number of main slides and nav slides is different. Expect incorrect behaviour.');
            }

            for (let i = 0; i < numberOfSlides; i++) {

                this.buttons = new ComponentArray(NSSliderGalleryButton, () => sliderNav[i].querySelectorAll('.nis-GalleryThumb_Slide'))

                this.buttons.map((_c,index) => {
                    const c = <NSSliderGalleryButton>_c
                    c.setClick(mainArray[i],index)
                })


                /*navArray[i].on('slideChange', function(){
                    mainArray[i].slideTo(navArray[i].realIndex);
                });

                mainArray[i].on('slideChange', function(){
                    if(navArray[i].realIndex != mainArray[i].realIndex){
                        navArray[i].slideToLoop(mainArray[i].realIndex);
                    }
                });*/
            }

        }


    }
}

