import Component from '../lib/Component'
import ComponentArray from '../lib/ComponentArray'
import MenuLink from './MenuLink'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import gsap from 'gsap'

export type ComponentList = () => Component[]

export default class MenuButton extends Component {
    control: HTMLElement
    device:string | null
    direction: string | null
    allElements: NodeListOf<Element>
    gsap:GSAPTween
    links:ComponentArray
    onClickLink:(e:Event) => void

    mount() {
        let controlID,_control;
        controlID = this.element.getAttribute('aria-controls')
        _control = this.getControl(controlID)
        if(!_control) return
        this.control = _control
        this.setOtherButton(controlID)
        this.device = this.control.getAttribute('data-device')
        this.direction = this.control.getAttribute('data-direction')
        this.element.setAttribute('aria-haspopup', 'true')
        this.resize()
    }

    changeMediaquery() {
        this.resize()
    }

    resize(){
        this.changeState(false)
        if(this.gsap && this.isOpen()) this.close()
        this.off('click')
        if(this.device && this.mediaQuery.screenMode != this.device){
            this.control.setAttribute('style',"position: static;width: inherit;height:inherit;")
            return
        }
        this.control.setAttribute("style", "display:none; position: fixed;top: 0;left: 0;width: 100%;height: 100vh;z-index: 5;")
        this.on('click', () => {this.onClick()})
        this.menuLinks()
    }

    onClick(){
        this.setAnimation()
        if(!this.isOpen()){
            this.changeState(true)
            this.control.style.display = 'block';
            this.open()
        }else{
            this.changeState(false)
            this.close()
        }
    }

    open(){
        this.gsap = this.gsap.play()
        disableBodyScroll(this.control, {
            allowTouchMove: (el:any) => {
                while (el && el !== document.body) {
                    if (el.getAttribute('body-scroll-lock-ignore') !== null) return true
                    el = el.parentNode
                }
            },
        })
        this.links.on('click', this.onClickLink)
    }

    close(){
        this.gsap = this.gsap.reverse(1)
        enableBodyScroll(this.control)
        this.links.off('click', this.onClickLink)
    }

    getControl(controlID:string | null){
        return (controlID)? document.getElementById(controlID) : null;
    }

    setOtherButton(controlID:string | null){
        const _elements = document.querySelectorAll('[aria-controls='+controlID+']')
        if(_elements.length > 1){
            this.allElements = _elements;
        }
    }

    setAnimation(){
        let animationFrom = <any>{},animationTo = <any>{}
        switch (this.direction) {
            case 'left':
                animationFrom = {left:'100%',x: 0}
                animationTo = {left:'100%',x:-window.innerWidth}
                break;
            case 'right':
                animationFrom = {left:0,x:-window.innerWidth}
                animationTo = {left:0,x:0}
                break;
            case 'up':
                animationFrom = {left:0,top:'100%',y:'100%'}
                animationTo = {left:0,top:'0',y:'0'}
                break;
            case 'down':
                animationFrom = {left:0,y:'-100%'}
                animationTo = {left:0,y:0}
                break;
            default:
                animationFrom = {opacity:0}
                animationTo = {opacity:1}
        }
        animationTo.onReverseComplete = ()=>{this.control.style.display = 'none'};
        this.gsap = gsap.fromTo(this.control, animationFrom,animationTo).pause()
    }

    isOpen() {
        return this.control.classList.contains('is-open')
    }

    changeState(status:boolean){
        if(this.allElements){
            Array.from(this.allElements).forEach((el) => {
                el.setAttribute('aria-expanded',String(status))
            })
        }
        if(status){
            this.control.classList.add('is-open')
            document.body.classList.add("is-menu-open")
        }else{
            this.control.classList.remove('is-open')
            document.body.classList.remove("is-menu-open")
        }

    }

    menuLinks(){
        this.links = new ComponentArray(MenuLink, () => this.control.querySelectorAll('a'))
        this.onClickLink = (e) => {this.scrollAnchorLink(e)}
    }

    scrollAnchorLink(e:Event) {
        const target = e.currentTarget;
        if(target instanceof HTMLAnchorElement){
            const targetURL = target.getAttribute('href')
            if (targetURL) {
                const targetHash = targetURL.match(/#(.*)+/g)
                if(targetHash && target.pathname === location.pathname){
                    this.close()
                }
            }
        }
    }
}

