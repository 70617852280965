import Component from '../lib/Component'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

export default class NSSliderGalleryButton extends Component {
    dateElement:HTMLElement|null
    slider:any
    date:string
    koyomi:number
    mount() {
        this.koyomi = Number(this.element.dataset.koyomi);
    }

    setClick(Slider:any,SliderIndex:number){
        this.slider = Slider
        this.on('click',(event)=>{
            this.slider.slideTo(SliderIndex)
        })
    }

}

